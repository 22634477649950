import Masonry from "masonry-layout";
import imagesLoaded from "imagesloaded";
import Glightbox from "glightbox";
import Splide from '@splidejs/splide';

var IconArt = new function () {
  this.init = function () {
    document.documentElement.classList.add('js');

    this.collapsibleMenu();
    this.cardsLayout();
    this.lightBoxes();
    this.reactiveForms();
    if (this.isPageType("exhibition") || this.isPageType("article")) {
      this.photoSliders();
    }
    if (this.isPageType("index")) {
      this.stickyHeader();
      this.splideMaybe('.exhibitions');
      this.splideMaybe('.feedbacks');
    }
    if (this.isPageType("exhibitions")) {
      this.splideMaybe('.exhibitions');
    }
  }

  /* === utils === */
  this.isPageType = function (pageType) {
    return document.body.classList.contains(`page-type-${pageType}`);
  }

  /* === header === */
  this.stickyHeader = function () {
    let header = document.getElementById('header');
    let observerElem = document.getElementById('header-intersect-observer');
    let callback = function (elements) {
      let el = elements[0].target;
      if (window.scrollY >= el.offsetTop) {
        header.classList.add("stick");
      } else {
        header.classList.remove("stick");
      }
    };

    let observer = new IntersectionObserver(callback);
    observer.observe(observerElem);
  }

  this.collapsibleMenu = function () {
    const toggler = document.querySelector('.menu-collapse-button');
    const toggled = document.querySelectorAll('.mobile-toggled');

    const toggleMenu = function () {
      toggled.forEach(el => el.classList.toggle("reveal"));
    };

    toggler.addEventListener("click", toggleMenu);
  };

  /* === cards === */
  this.cardsGutter = 72;
  this.cardsLayout = function () {
    let selector = ".artwork-cards";
    imagesLoaded(selector, () => {
      let msnry = new Masonry(selector, {
        columnWidth: ".card",
        itemSelector: ".card",
        percentPosition: true,
        gutter: this.cardsGutter,
      });
    });
  }

  /* === catalogue === */
  this.reactiveForms = function () {
    function reactiveForm(form) {
      if (form) {
        const inputs = form.querySelectorAll("select");
        inputs.forEach(f => f.addEventListener("input", () => form.submit()));
      }
    };
    reactiveForm(document.querySelector(".filters"));
    reactiveForm(document.querySelector(".currency-selector"));
  };

  /* === sliders === */
  this.photoSliders = function () {
    new Splide('.splide', {
      perPage: 2,
      gap: "1rem",
      fixedHeight: "22rem",
      cover: true,
      breakpoints: {
        600: {
          perPage: 1,
        }
      }
    }).mount();
  };

  this.splideMaybe = function (selector, arrows = true) {
    const items = document.querySelectorAll(selector + ' .splide__slide');
    const is_multi = items.length > 1;
    new Splide(selector, {
      pagination: is_multi,
      arrows: arrows && is_multi,
    }).mount();
  };

  /* === lightboxes === */
  this.lightBoxes = function () {
    Glightbox({
      selector: '.lightbox',
    });
  };
}

IconArt.init();
